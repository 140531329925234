/* App.css */

@font-face {
  font-family: 'AppleSDGothicNeo';
  src: url('./static/fonts/AppleSDGothicNeoB.ttf') format('truetype');
}

@font-face {
  font-family: 'JMH';
  src: url('./static/fonts/JMH Typewriter.ttf') format('truetype');
}

.App {
  display: block;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  
  /* background-color: black; */
}

body {
  margin: 0;
  cursor: crosshair;
}

canvas {
  touch-action: none;
}

.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative; 
}

.canvas {
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mask {
  mask-image: url("/public/centerblur.png");
  mask-size: 100% 100%;
  position: fixed;
  z-index: 20;
  border-radius: 50%;
}


.logo-container {
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 21;
  text-align: center;
  mix-blend-mode: soft-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.logo-container img {
  width: 400px;
}

.logo-container p {
  font-size: 22px;
  font-weight: bold;
  font-family: 'AppleSDGothicNeo';
  margin: 0;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px; 
  opacity: 1;
  transition: all 1s;
}

.panel {
  width: 100vw;
  height: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: 50;
  transition: all ease-out 0.3s;
  opacity: 1;
}

.panel.open {
  visibility: visible;
}

.panel .backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0);
  z-index: 50;
  transition: all ease-out 0.5s 0.7s;
}

.panel.open .backdrop {
  background: rgba(0,0,0,0.7);
  transition: all ease-in 0.5s;
}

.panel video {
  width: 65%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all ease-out 0.5s;
  opacity: 0;
  z-index: 51;
  border-radius: 42px 0px 42px 42px;
}

.panel.open video {
  opacity: 1;
  transition: all ease-in 0.5s 0.5s;
}

.panel .closeBtn {
  display: none;
  visibility: hidden;
  width: 74px;
  height: 74px;
  background: transparent;
  position: fixed;
  top: 19%;
  left: 80.5%;
  font-size: 36px;
  color: white;
  cursor: pointer;
  transform: translate(-50%, -50%);
  transition: all ease-out 0.2s;
  z-index: 52;
}

.panel.open .closeBtn {
  display: block;
  visibility: visible;
}

.annotation {
  cursor: pointer;
  font-size: 32px;
  color: white;
  transition: all 0.3s;
  font-family: 'JMH';
}

.annotation:hover {
  /* background: rgba(255,255,255,0.7); */
  transform: scale(1.2);
  text-shadow: 0 0 0.1em #fff, 0 0 0.2em #fff
}